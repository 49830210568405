
interface cardapioLoja {
    loja:null,
    itens:any[],
    cardapio:any[],
    configuracaoCardapio:any[],
    cardapioParcelas:any[],
    itensDinamicos:any[],
    itensFixos:any[],
    estoque:ItemEstoque[],
    grupoExibicao: GrupoExibicao[]
}

export interface ItemEstoque {
  alterado?: null | string;
  codConcessionaria?: number | null;
  codProduto?: number | null;
  codProdutoLoja?: string | null;
  created_at?: string | null;
  estoque?: number | null;
  grupoExibicao?: string | null;
  imgProduto?: string | null;
  informacao_extra?: string | null;
  lubricanteFracionado?: string | null;
  nomeReferencia?: string | null;
  preco?: string | null;
  produto?: string | null;
  tempoMo?: string | null;
  unidade?: string | null;
  updated_at?: string | null;
  valorMo?: string | null;
  videoProduto?: string | null;
}

export interface Produto {
    servico: string | null;
    dadosExtra: string | number | null;
    codItemLoja: string | null;
    codigoMaoObra: string | null;
    valorMaoObra: string | null;
    tempoMaoObra: string | null;
    iniciarComMarcacao: string | null;
    precoPeca: string | null;
    codAgregados: number | null;
    dinamico: string | null;
    imagem: string | null;
    video: string | null;
    descricao: string | null;
    tituloDoForm: string | null;
    grupo: string | null;
    codConcessionaria: number | null;
    itensAgrupados?: ItensAgrupados[] | null;
    estoque: any | null;
    qtd: any | null;
    codigoMaoObraAlacarte: any | null;
    codConfigPacote: any | null;
    codigoPeca: any | null;
}

export interface ItensAgrupados {
    nome: string | null;
    valorMo: string | null;
    codItemLoja: string | null;
}

export interface Pacote {
    acrescimoalacarte: number | null;
    codConcessionaria: number | null;
    codPacote: string | null;
    descricao: string | null;
    id: number | null;
    idCardapio: string | null;
    jurosApos: number | null;
    jurosDe: number | null;
    nome: string | null;
    nomeFantasia: string | null;
    grupo: string | null;
    ordem: number | null;
    parcelaPadrao: number | null;
    parcelas: number | null;
    tituloDoForm: string | null;
    tipoOS: string | null;
}

export interface Cardapio {
    tituloDoForm: string | null;
    codConcessionaria: number | null;
    exibirNoTopo: string | null;
    id: number | null;
    nome: string | null;
    padrao: string | null;
    qtd: number | null;
    tipoCardapio: string | null;
}

export interface GrupoExibicao {
    grupoExibicao: string
}

import Api from '@/services/Api'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'
import Title from "@/components/Title.vue";
import Button from "@/components/Button.vue";
import Folder from "./components/Folder.vue";
import FormEdit from './components/FormEdit.vue';
import {useChangeContainer} from '@/store/ChangeContainer'
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import DraggableList from './components/DraggableList.vue';
import useAlert from "@/composables/Alert";

export default defineComponent({
    name: "Cardapio",
    components: {Title, Button, Folder, FormEdit, DraggableList},
    props: [],
    setup() {
        const { showTimeAlert } = useAlert();        
        const route = useRoute()
        const dialogVisible: Ref<boolean> = ref(false);
        const iniciarComMarcacao: Ref<number | null> = ref(null);
        const getUseChangeContainer = useChangeContainer()
        const idLoja: Ref<number | null> = ref(null)
        const idDeleteCardapio: Ref<number | null> = ref(null)
        const codConfigPacote: Ref<number | null> = ref(null)
        const formItem: Ref<Produto> = ref({
            servico:  null,
            dadosExtra:  null,
            codItemLoja:  null,
            codigoMaoObra:  null,
            valorMaoObra:  null,
            tempoMaoObra:  null,
            iniciarComMarcacao:  null,
            precoPeca:  null,
            dinamico: null,
            codAgregados:  null,
            tituloDoForm: null,
            codConcessionaria: null,
            imagem:  null,
            video:  null,
            descricao:  null,
            grupo:  null,
            // itensAgrupados: [{nome: null,valorMo: null,codItemLoja: null}],
            estoque: null,
            qtd: null,
            codigoMaoObraAlacarte: null,
            codConfigPacote: null,
            codigoPeca: null,
        })
        const formPacote: Ref<Pacote> = ref({
            acrescimoalacarte: null,
            codConcessionaria: null,
            codPacote: null,
            descricao: null,
            id: null,
            idCardapio: null,
            jurosApos: null,
            jurosDe: null,
            nome: null,
            nomeFantasia: null,
            ordem: null,
            parcelaPadrao: null,
            parcelas: null,
            tituloDoForm: null,
            grupo: null,
            tipoOS: null,

        })
        const formCardapio: Ref<Cardapio> = ref({
            codConcessionaria: null,
            exibirNoTopo: null,
            id: null,
            nome: null,
            padrao: null,
            qtd: null,
            tituloDoForm: null,
            tipoCardapio: null
        })
        const itensPacote: Ref<Produto[]> = ref([])
        const typeForm: Ref<string> = ref('')
        const openEdit: Ref<any> = ref(false)
        const openDragDrop: Ref<any> = ref(false)

        const cardapioLoja:Ref<cardapioLoja> = ref({
            loja:null,
            itens:[],
            cardapio:[],
            configuracaoCardapio:[],
            cardapioParcelas:[],
            itensDinamicos:[],
            itensFixos:[],
            estoque:[],
            grupoExibicao: []  
        })

        async function getCardapioById(id: number){
            const {data} = await Api.get("getCardapioById", {id})
            cardapioLoja.value = data
            idLoja.value = data.loja.codConcessionaria
        }

        async function populaForm(chaveObj: string, id: number){
            openDragDrop.value = false
            resetObject(formItem.value)
            resetObject(formPacote.value)
            resetObject(formCardapio.value)
            if(chaveObj === null && id === null) { return; }
            if(!id){
                typeForm.value = chaveObj
                openEdit.value = true
                return;
            }
            if(chaveObj ==  'itens' as string) {
                if(cardapioLoja.value[chaveObj]){
                    const finded = cardapioLoja.value[chaveObj].find((item) => item.codAgregados === id)
                    console.log(finded)
                    if(finded !== undefined){
                        formItem.value = { ...finded, tituloDoForm: finded.servico}
                        typeForm.value = chaveObj
                        openEdit.value = true
                        return;
                    }
                }
                formItem.value.codConfigPacote = String(id)
                typeForm.value = chaveObj
                openEdit.value = true
            }
            if(chaveObj ==  'configuracaoCardapio' as string) {
                const finded = cardapioLoja.value[chaveObj].find((item) => item.id === id)
                if(finded !== undefined){
                    formPacote.value = {...finded, tituloDoForm: finded?.descricao}
                    typeForm.value = chaveObj
                    openEdit.value = true
                    return;
                }
                formPacote.value.idCardapio = String(id)
                typeForm.value = chaveObj
                openEdit.value = true
            }
            if(chaveObj ==  'cardapio' as string) {
                const finded = cardapioLoja.value[chaveObj].find((item) => item.id === id)
                if(finded !== undefined){
                    formCardapio.value = {...finded,  tituloDoForm: finded.nome}
                    typeForm.value = chaveObj
                    openEdit.value = true
                    return;
                }
                formCardapio.value.codConcessionaria = id
                typeForm.value = chaveObj
                openEdit.value = true
            }
        }


        async function deleteAnyCardapio(item: object){
            try {
                if(typeForm.value === 'itens'){ const res = await Api.delete('cardapio/deleteItem', {...item})}
                if(typeForm.value === 'configuracaoCardapio'){ const res = await Api.delete('cardapio/deletePacote', {...item})}
                if(typeForm.value === 'cardapio'){const res = await Api.delete('cardapio/deleteCardapio', {...item})}
                dialogVisible.value = false
                closeForm()
                showTimeAlert('Excluido!', 'success');
                await getCardapioById(idLoja.value as number)   
            } catch (error) {
                console.log("error", error);
                showTimeAlert('Tente novamente', 'error');
            }
        }

        async function ordenaItens(itens: Produto[]) {
            try{
                const newOrder = { idLoja: idLoja.value, codConfigPacote: codConfigPacote.value, itens: itens.map((prod) => prod.codAgregados) }
                const res = await Api.post('cardapio/ordenaItens', {...newOrder})
                if(!res.data.error){
                    await getCardapioById(idLoja.value as number)
                    showTimeAlert('Lista reordenada!', 'success');
                    return;
                }
                showTimeAlert('Tente novamente', 'error');
            } catch (error) {
                console.log("error", error);
            }
        }

        async function resetObject(obj) {
            for (const prop in obj) {
                obj[prop] = typeof obj[prop] === "object" ? null : null;
            }
        }

        function montaArrayItensPacote(id: number){
            itensPacote.value = []
            itensPacote.value = cardapioLoja.value.itens.filter((prod) => prod.codConfigPacote === id);
        }
        
        function closeForm(){
            openEdit.value = !openEdit.value
        }

        function idDeleteCardapioFunction(id: number){
            idDeleteCardapio.value = id
            dialogVisible.value = true
        }

        // watch(() => idDeleteCardapio.value , )
        // deleteAnyCardapio({codConcessionaria: idLoja, cardapioPacote: tituloCardapio.id})

        onBeforeRouteLeave((to, from) => {
            getUseChangeContainer.close()
        })

        const valor = computed(() => {
            return window.location.pathname;
        })

        onMounted(() => {
            getCardapioById(idLoja.value = route.query.idLoja as any)
            if(valor.value.split('/')[1] === 'cardapio'){
                getUseChangeContainer.open()
            }
        })

        return { cardapioLoja, formItem, populaForm, dialogVisible, openEdit, typeForm, formCardapio, formPacote, getCardapioById, iniciarComMarcacao, idLoja, idDeleteCardapioFunction, idDeleteCardapio, deleteAnyCardapio, montaArrayItensPacote, itensPacote, openDragDrop, codConfigPacote, ordenaItens, resetObject, closeForm }
    },
})
